import React from 'react';
import { TextField, Select, MenuItem, Grid, Button, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import customItemData from '../../data/customItemData.json';

const ItemSearch = ({
                        selectedRealm, setSelectedRealm,
                        searchTerm, setSearchTerm,
                        categoryFilter, setCategoryFilter,
                        slotFilter, setSlotFilter,
                        filters, setFilters,
                        uniqueCategories, uniqueSlots
                    }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const secondaryColor = theme.palette.secondary.main;

    // Add a new filter with default values
    const addFilter = () => {
        const firstType = Object.keys(customItemData[selectedRealm])[0];
        const firstId = customItemData[selectedRealm][firstType][0] || '';

        const newFilter = {
            type: firstType,
            operator: '>=',
            value: '0',
            id: firstId
        };

        setFilters([...filters, newFilter]);
    };

    // Reset all filters to their initial state
    const resetFilters = () => {
        setSearchTerm('');
        setCategoryFilter('');
        setSlotFilter('');
        setFilters([]);
    };

    // Update filter values when changed
    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        newFilters[index] = { ...newFilters[index], [field]: value };

        // Update ID options based on selected type
        if (field === 'type') {
            const ids = customItemData[selectedRealm][value];
            newFilters[index].id = ids.length > 0 ? ids[0] : '';
        }
        setFilters(newFilters);
    };

    // Remove a filter
    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    return (
        <Grid item xs={12} md={3}>
            {/* Realm Selection */}
            <FormControl fullWidth>
                <InputLabel style={{ color: primaryColor }}>Realm</InputLabel>
                <Select
                    value={selectedRealm}
                    onChange={(e) => setSelectedRealm(e.target.value)}
                    style={{ color: primaryColor }}
                >
                    <MenuItem value="Albion">Albion</MenuItem>
                    <MenuItem value="Hibernia">Hibernia</MenuItem>
                    <MenuItem value="Midgard">Midgard</MenuItem>
                </Select>
            </FormControl>

            {/* Name Search */}
            <TextField
                fullWidth
                label="Search by name"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginTop: '10px', color: primaryColor }}
                InputLabelProps={{ style: { color: primaryColor } }}
                InputProps={{ style: { color: primaryColor } }}
            />

            {/* Category and Slot Filters */}
            <FormControl fullWidth style={{ marginTop: '10px' }}>
                <InputLabel style={{ color: primaryColor }}>Category</InputLabel>
                <Select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    style={{ color: primaryColor }}
                >
                    <MenuItem value="">All</MenuItem>
                    {uniqueCategories.map(category => (
                        <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '10px' }}>
                <InputLabel style={{ color: primaryColor }}>Slot</InputLabel>
                <Select
                    value={slotFilter}
                    onChange={(e) => setSlotFilter(e.target.value)}
                    style={{ color: primaryColor }}
                >
                    <MenuItem value="">All</MenuItem>
                    {uniqueSlots.map(slot => (
                        <MenuItem key={slot} value={slot}>{slot}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Dynamic Filters */}
            {filters.map((filter, index) => (
                <Grid container spacing={1} key={index} style={{ marginTop: '10px' }}>
                    <Grid item xs={4}>
                        <Select
                            value={filter.type}
                            onChange={(e) => handleFilterChange(index, 'type', e.target.value)}
                            fullWidth
                            style={{ color: primaryColor }}
                        >
                            {Object.keys(customItemData[selectedRealm]).map(type => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            value={filter.id || ''}
                            onChange={(e) => handleFilterChange(index, 'id', e.target.value)}
                            fullWidth
                            style={{ color: primaryColor }}
                        >
                            {(customItemData[selectedRealm][filter.type] || []).map(id => (
                                <MenuItem key={id} value={id}>{id}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            type="number"
                            value={filter.value}
                            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                            fullWidth
                            InputProps={{ style: { color: primaryColor } }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={() => removeFilter(index)} style={{ color: primaryColor }}>Remove</Button>
                    </Grid>
                </Grid>
            ))}

            {/* Add Filter and Reset Filters Buttons */}
            <Button
                onClick={addFilter}
                variant="outlined"
                fullWidth
                style={{ marginTop: '10px', color: primaryColor, borderColor: primaryColor }}
            >
                Add Filter
            </Button>
            <Button
                onClick={resetFilters}
                variant="outlined"
                fullWidth
                style={{ marginTop: '10px', color: secondaryColor, borderColor: secondaryColor }}
            >
                Reset Filters
            </Button>
        </Grid>
    );
};

export default ItemSearch;
