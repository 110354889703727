import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, MenuItem, Select, CircularProgress, Chip, Box } from '@mui/material';
import { db } from '../../firebase/config';
import { collection, query, getDocs, where, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useTheme } from '@mui/material/styles';

const CharactersView = ({ character }) => {
    const { user } = useAuthContext();
    const [characterDetails, setCharacterDetails] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
    const theme = useTheme();

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    const chipColors = {
        1: 'red',
        2: 'blue',
        3: 'green'
    };

    const fetchCharacterData = async (characterId) => {
        try {
            const url = `https://api.camelotherald.com/character/info/${characterId}`;
            const response = await fetch(url);
            const data = await response.json();
            setCharacterDetails(data);
        } catch (error) {
            console.error('Error fetching character data:', error);
        }
    };

    const fetchTemplates = async () => {
        try {
            const q = query(
                collection(db, 'templates'),
                where('owner', '==', user.uid),
                where('realm', '==', character.realm),
                where('class', '==', character.class)
            );
            const querySnapshot = await getDocs(q);
            const fetchedTemplates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTemplates(fetchedTemplates);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    const fetchTemplateDetails = async (templateId) => {
        try {
            const templateDocRef = doc(db, 'templates', templateId);
            const templateDoc = await getDoc(templateDocRef);
            if (templateDoc.exists()) {
                setSelectedTemplateDetails(templateDoc.data());
                console.log('Selected Template:', templateDoc.data());
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
        }
    };

    useEffect(() => {
        if (character?.characterId) {
            fetchCharacterData(character.characterId);
            fetchTemplates();
            if (character.template) {
                setSelectedTemplate(character.template);
                fetchTemplateDetails(character.template);
            }
        }
    }, [character]);

    const handleTemplateChange = async (event) => {
        const templateId = event.target.value;
        setSelectedTemplate(templateId);
        const characterDocRef = doc(db, `users/${user.uid}/characters`, character.id);

        if (templateId) {
            try {
                await updateDoc(characterDocRef, { template: templateId });
                fetchTemplateDetails(templateId);
            } catch (error) {
                console.error('Error updating character with template:', error);
            }
        } else {
            try {
                await updateDoc(characterDocRef, { template: '' });
                setSelectedTemplateDetails(null);
                console.log("Template Removed")
            } catch (error) {
                console.error('Error removing template from character:', error);
            }
        }
    };

    return (
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
            {characterDetails ? (
                <div>
                    <Box display="flex" alignItems="center" justifyContent="space-between" padding="16px" bgcolor={theme.palette.background.paper}>
                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                            {characterDetails.name}
                        </Typography>
                        <Chip
                            label={characterDetails.class_name}
                            variant="outlined"
                            style={{ borderColor: chipColors[characterDetails.realm], color: chipColors[characterDetails.realm] }}
                        />
                        <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                            Race: {characterDetails.race}
                        </Typography>
                        <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                            Realm: {realmMapping[characterDetails.realm]}
                        </Typography>
                        <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                            Server: {characterDetails.server_name}
                        </Typography>
                    </Box>

                    <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.main, marginBottom: '8px' }}>
                            Realm Points and Kills
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                                    Realm Points: {characterDetails.realm_war_stats?.current?.realm_points}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                                    Total Kills: {characterDetails.realm_war_stats?.current?.player_kills?.total?.kills}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                                    Albion Kills: {characterDetails.realm_war_stats?.current?.player_kills?.albion?.kills}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                                    Midgard Kills: {characterDetails.realm_war_stats?.current?.player_kills?.midgard?.kills}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                                    Hibernia Kills: {characterDetails.realm_war_stats?.current?.player_kills?.hibernia?.kills}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper style={{ padding: '16px' }}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.main, marginBottom: '8px' }}>
                            Select Template
                        </Typography>
                        <Select
                            value={selectedTemplate}
                            onChange={handleTemplateChange}
                            fullWidth
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {templates.map(template => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Paper>
                </div>
            ) : (
                <CircularProgress />
            )}
        </Paper>
    );
};

export default CharactersView;
