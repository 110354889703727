import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import ItemSearch from '../../components/items/ItemSearch';
import ItemList from '../../components/items/ItemList';
import ItemDetails from '../../components/templates/shared/ItemDetails';
import AlbionItems from '../../data/items/Albion_items.json';
import HiberniaItems from '../../data/items/Hibernia_items.json';
import MidgardItems from '../../data/items/Midgard_items.json';

const realmData = { Albion: AlbionItems, Hibernia: HiberniaItems, Midgard: MidgardItems };

const Items = () => {
    const [selectedRealm, setSelectedRealm] = useState('Albion');
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [slotFilter, setSlotFilter] = useState('');
    const [filters, setFilters] = useState([]); // Ensure filters is always an array
    const [lastHoveredItem, setLastHoveredItem] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(false);

    // Load initial items when realm changes or filters are reset
    useEffect(() => {
        setLoading(true);
        const items = realmData[selectedRealm];
        setDisplayedItems(items);
        setPage(0); // Reset to first page on realm change
        setLoading(false);
    }, [selectedRealm]);

    // Apply all filters (basic and dynamic) to the entire dataset
    useEffect(() => {
        setLoading(true);

        // Filter logic
        const filtered = displayedItems.filter(item => {
            const matchesName = item.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = categoryFilter ? item.category === categoryFilter : true;
            const matchesSlot = slotFilter ? item.slot === slotFilter : true;
            const matchesBasicFilters = matchesName && matchesCategory && matchesSlot;

            // Apply dynamic filters, with a default to empty array if filters is undefined
            const matchesDynamicFilters = (filters || []).every(filter => {
                if (!Array.isArray(item.bonuses)) return false;
                const itemBonus = item.bonuses.find(bonus => bonus.type === filter.type && (!filter.id || bonus.id === filter.id));
                return itemBonus && parseInt(itemBonus.value, 10) >= parseInt(filter.value, 10);
            });

            return matchesBasicFilters && matchesDynamicFilters;
        });

        setFilteredItems(filtered);
        setPage(0); // Reset to first page when filters change
        setLoading(false);
    }, [searchTerm, categoryFilter, slotFilter, filters, displayedItems]);

    return (
        <Grid container spacing={2}>
            <ItemSearch
                selectedRealm={selectedRealm} setSelectedRealm={setSelectedRealm}
                searchTerm={searchTerm} setSearchTerm={setSearchTerm}
                categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter}
                slotFilter={slotFilter} setSlotFilter={setSlotFilter}
                filters={filters} setFilters={setFilters}
                uniqueCategories={[...new Set(displayedItems.map(item => item.category))]}
                uniqueSlots={[...new Set(displayedItems.map(item => item.slot))]}
            />

            {/* Show loading spinner if data is being processed */}
            {loading ? (
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <ItemList
                    visibleItems={filteredItems.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(e, newPage) => setPage(newPage)}
                    handleRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                    displayedItems={filteredItems}
                    setLastHoveredItem={setLastHoveredItem}
                />
            )}

            <Grid item xs={12} md={3}>
                <ItemDetails item={lastHoveredItem} />
            </Grid>
        </Grid>
    );
};

export default Items;
