import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Grid, TableFooter, IconButton, Collapse, Card, CardContent, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import realmRanks from '../../../data/realm_ranks.json';

const GaherisCharacterTable = ({ characters }) => {
    const [characterDetails, setCharacterDetails] = useState({});
    const [filteredCharacters, setFilteredCharacters] = useState([]);
    const [selectedRealm, setSelectedRealm] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [openCollapse, setOpenCollapse] = useState({}); // Keep track of collapsed rows

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    const fetchCharacterData = async (characterId) => {
        try {
            const url = `https://api.camelotherald.com/character/info/${characterId}`;
            const response = await fetch(url);
            const data = await response.json();
            setCharacterDetails(prev => ({ ...prev, [characterId]: data }));
        } catch (error) {
            console.error('Error fetching character data:', error);
        }
    };

    useEffect(() => {
        characters.forEach(character => {
            fetchCharacterData(character.characterId);
        });
    }, [characters]);

    useEffect(() => {
        const filtered = characters.filter(character => {
            const details = characterDetails[character.characterId] || {};
            const realmName = realmMapping[details.realm];
            return (
                (!selectedRealm || realmName === selectedRealm) &&
                (!selectedClass || details.class_name === selectedClass)
            );
        });
        setFilteredCharacters(filtered);
    }, [characters, characterDetails, selectedRealm, selectedClass]);

    const handleCollapseToggle = (characterId) => {
        setOpenCollapse(prev => ({ ...prev, [characterId]: !prev[characterId] }));
    };

    const calculateRealmRank = (realmPoints) => {
        for (const rankInfo of realmRanks) {
            const { min_rp, max_rp, levels, rank } = rankInfo;
            if (realmPoints >= min_rp && (max_rp === undefined || realmPoints <= max_rp)) {
                // Check if levels array exists
                if (!levels || levels.length === 0) {
                    return `${rank}L0`; // Default to level 0 if no levels are defined
                }

                // Find the level within the rank by checking the realm points against the levels array
                let level = 0;
                for (let i = 0; i < levels.length; i++) {
                    if (realmPoints < levels[i]) {
                        level = i; // The current level is the index + 1
                        break;
                    } else if (i === levels.length - 1) {
                        level = levels.length; // Last step in the rank
                    }
                }
                return `${rank}L${level}`;
            }
        }
        return ''; // Return an empty string if no rank is found
    };

    const calculateTotals = (characters) => {
        return characters.reduce((totals, character) => {
            const details = characterDetails[character.characterId] || {};
            const realmWarStats = details.realm_war_stats?.current || {};

            totals.realmPoints += realmWarStats.realm_points || 0;

            return totals;
        }, {
            realmPoints: 0
        });
    };

    const totals = calculateTotals(filteredCharacters);

    const boldFooterStyle = { fontWeight: 'bold', fontSize: '1rem', color: primaryColor, borderRight: `1px solid ${primaryColor}` };

    return (
        <div>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Realm</InputLabel>
                        <Select value={selectedRealm} onChange={(e) => setSelectedRealm(e.target.value)}>
                            <MenuItem value=''>All Realms</MenuItem>
                            <MenuItem value='Albion'>Albion</MenuItem>
                            <MenuItem value='Midgard'>Midgard</MenuItem>
                            <MenuItem value='Hibernia'>Hibernia</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Class</InputLabel>
                        <Select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                            <MenuItem value=''>All Classes</MenuItem>
                            {Array.from(new Set(characters.map(character => characterDetails[character.characterId]?.class_name)))
                                .filter(Boolean)
                                .map(cls => (
                                    <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table size="small"> {/* Makes the table dense */}
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ color: primaryColor }}>Name</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm Rank</TableCell>
                            <TableCell style={{ color: primaryColor }}>Class</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm</TableCell>
                            <TableCell style={{ color: primaryColor }}>LVL</TableCell>
                            <TableCell style={{ color: primaryColor }}>Race</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCharacters.map(character => {
                            const details = characterDetails[character.characterId] || {};
                            const realmWarStats = details.realm_war_stats?.current || {};
                            const realmRank = calculateRealmRank(realmWarStats.realm_points || 0);

                            return (
                                <React.Fragment key={character.id}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleCollapseToggle(character.characterId)}
                                                aria-label="expand row"
                                                size="small"
                                            >
                                                {openCollapse[character.characterId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ color: primaryColor }}>{character.name}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmRank}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.class_name || character.class}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmMapping[details.realm]}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.level}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.race}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmWarStats.realm_points || 0}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                            <Collapse in={openCollapse[character.characterId]} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography variant="h6" gutterBottom>
                                                                Realm Point Meter (Placeholder)
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7} style={boldFooterStyle}>Total</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.realmPoints}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default GaherisCharacterTable;
