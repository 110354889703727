import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/config';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Snackbar,
    IconButton,
    InputAdornment,
    Switch,
    FormControlLabel,
    Drawer,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import TemplateContainer from "./TemplateContainer";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import TemplateExportButton from "../view/TemplateExportButton";


const TemplateEdit = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const ADMIN_UID = 'tLatpSszTRanQOEASTUxHDfKq933';

    const { realm, class: className, id } = useParams();
    const [template, setTemplate] = useState({
        name: '',
        realm: '',
        class: '',
        level: ''
    });
    const [editableName, setEditableName] = useState(false);
    const [saveStatus, setSaveStatus] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [gearBySlot, setGearBySlot] = useState({});
    const [inventory, setInventory] = useState({
        "Torso": [],
        "Helm": [],
        "Arms": [],
        "Hands": [],
        "Legs": [],
        "Feet": [],
        "Necklace": [],
        "Cloak": [],
        "Jewel": [],
        "Belt": [],
        "L Ring": [],
        "R Ring": [],
        "L Wrist": [],
        "R Wrist": [],
        "Mythirian": [],
        "Main Hand": [],
        "Off Hand": [],
        "Two-Hand": [],
        "Ranged": []
    });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [sharedUserDisplayName, setSharedUserDisplayName] = useState('');
    const [sharedUsers, setSharedUsers] = useState(template.sharedUsers || []);
    const [isOwner, setIsOwner] = useState(false); // New state variable
    const [statistics, setStatistics] = useState({});

    const handleSaveTemplate = async () => {
        try {
            if (!user) {
                console.error('User not authenticated');
                return;
            }

            const isPrivate = template.isPrivate !== undefined ? template.isPrivate : true;

            // Update the template in the templates collection
            const templateDocRef = doc(db, `templates`, id);
            await updateDoc(templateDocRef, {
                ...template,
                items: gearBySlot,
                inventory: inventory,
                isPrivate: isPrivate,
                lastUpdated: new Date(),
            });

            setSaveStatus('Template saved successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error saving template:', error);
            setSaveStatus('Error saving template');
            setSnackbarOpen(true);
        }
    };

    const handleStatisticsCalculated = (calculatedStatistics) => {
        setStatistics(calculatedStatistics);
    };

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            handleSaveTemplate();
            e.preventDefault();
            e.returnValue = ''; // Standard for most browsers
            return 'Are you sure you want to leave? Changes you made may not be saved.'; // For some browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    });


    // Fetch the template data when the component mounts
    useEffect(() => {
        if (!user) {
            navigate('/login')
            return;
        }
        const fetchTemplate = async () => {
            try {
                const templateDocRef = doc(db, `templates`, id);
                const templateDocSnapshot = await getDoc(templateDocRef);

                if (templateDocSnapshot.exists()) {
                    const templateData = templateDocSnapshot.data();
                    if (templateData.owner !== user.uid && user.uid !== ADMIN_UID) {
                        navigate('/templates')
                        alert("You are not authorized to edit this template.");
                        return;
                    } else {
                        setTemplate(templateData);
                        setIsOwner(true);
                        if (templateData.items) {
                            setGearBySlot(templateData.items);
                        }
                        if (templateData.sharedUsers) {
                            setSharedUsers(templateData.sharedUsers);  // Set sharedUsers from fetched data
                        } else {
                            setSharedUsers([]);  // Initialize with an empty array if none exist
                        }
                    }
                    // Start with the existing inventory from the template, if available
                    const updatedInventory = templateData.inventory ? { ...templateData.inventory } : { ...inventory };

                    // Add new items from gearBySlot to the inventory
                    Object.keys(templateData.items || {}).forEach(slot => {
                        const itemInSlot = templateData.items[slot];
                        if (itemInSlot && !updatedInventory[slot].some(item => item.id === itemInSlot.id)) {
                            updatedInventory[slot] = [...(updatedInventory[slot] || []), itemInSlot];
                        }
                    });
                    setInventory(updatedInventory);
                } else {
                    console.log('Template not found.');
                }
            } catch (error) {
                console.error('Error fetching template:', error);
            }
        };
        fetchTemplate();
    }, [id, realm, className, user, navigate]);





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTemplate({ ...template, [name]: value });
    };

    const handleEditNameClick = () => {
        setEditableName(true);
    };

    const handleSaveNameClick = () => {
        setEditableName(false);
        handleSaveTemplate();
    };

    const handleCancelNameClick = () => {
        setEditableName(false);
        setTemplate({ ...template, name: template.name });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDrawerClick = (event) => {
        event.stopPropagation(); // Prevent click events from propagating to the Box
    };

    const handleAddSharedUser = async () => {
        if (sharedUserDisplayName) {
            try {
                const usersCollectionRef = collection(db, 'users');
                const q = query(usersCollectionRef, where('displayName', '==', sharedUserDisplayName));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    querySnapshot.forEach(async (userDoc) => {
                        const userData = userDoc.data();
                        const newUser = { uid: userDoc.id, displayName: userData.displayName };
                        if (!sharedUsers.some(user => user.uid === newUser.uid)) {
                            const newSharedUsers = [...sharedUsers, newUser];
                            const newSharedUserUids = [...(template.sharedUserUids || []), newUser.uid];

                            setSharedUsers(newSharedUsers);

                            // Now update this in Firestore
                            const templateDocRef = doc(db, 'templates', id);
                            await updateDoc(templateDocRef, {
                                sharedUsers: newSharedUsers,
                                sharedUserUids: newSharedUserUids
                            });
                        }
                    });
                } else {
                    console.error('User not found');
                }
            } catch (error) {
                console.error('Error fetching user or updating template:', error);
            }
            setSharedUserDisplayName(''); // Reset the input field
        }
    };

    const handleRemoveSharedUser = async (uid) => {
        const updatedSharedUsers = sharedUsers.filter(user => user.uid !== uid);

        // Initialize sharedUserUids as an empty array if it doesn't exist
        const updatedSharedUserUids = (template.sharedUserUids || []).filter(sharedUid => sharedUid !== uid);

        setSharedUsers(updatedSharedUsers);  // Update local state first

        try {
            // Update the template in the templates collection to reflect the change
            const templateDocRef = doc(db, 'templates', id); // Ensure 'id' is the ID of the current template
            await updateDoc(templateDocRef, {
                sharedUsers: updatedSharedUsers,
                sharedUserUids: updatedSharedUserUids
            });
            console.log('User removed successfully from shared users.');
        } catch (error) {
            console.error('Error removing user from template:', error);
        }
    };



    const handleNameKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSaveNameClick();
        }
    };

    return (
        isOwner ? (
            <React.Fragment>
                {/*The New Layout*/}
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={4} md={3} lg={4} xl={4}>
                        <Typography variant="body1">
                            {!editableName ? (
                                <>
                                    {template.name}
                                    <IconButton
                                        aria-label="edit"
                                        size="small"
                                        onClick={handleEditNameClick}
                                    >
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        name="name"
                                        value={template.name}
                                        onChange={handleInputChange}
                                        onKeyDown={handleNameKeyPress}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="save"
                                                        size="small"
                                                        onClick={handleSaveNameClick}
                                                    >
                                                        <CheckIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="cancel"
                                                        size="small"
                                                        onClick={handleCancelNameClick}
                                                    >
                                                        <CancelIcon fontSize="inherit" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={8} md={9} lg={4} xl={4} justifyContent="space-between" alignItems="center">
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography variant="body1">
                                <strong>Owner:</strong> {template.ownerName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography variant="body1">
                                <strong>Realm:</strong> {template.realm}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography variant="body1">
                                <strong>Class:</strong> {template.class}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                            <Typography variant="body1">
                                <strong>Level:</strong> {template.level}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={9} sm={9} md={9} lg={6} xl={6} sx={{ order: { sm: 1, md: 1, lg: 1, xl: 1 } }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!template.isPrivate}
                                        onChange={(e) => setTemplate({ ...template, isPrivate: !e.target.checked })}
                                    />
                                }
                                label={template.isPrivate ? "Private" : "Public"}
                            />
                        </Grid>

                        <Grid item xs={3} sm={3} md={3} lg={6} xl={6} sx={{ order: { sm: 2, md: 2, lg: 2, xl: 2 } }}>
                            <Box display="flex" justifyContent="flex-end">
                                <Box mr={1}>
                                <TemplateExportButton template={template} statistics={statistics} id={id} />
                                </Box>
                                <Button onClick={() => setDrawerOpen(true)} variant="outlined" sx={{ mr: 1 }}>
                                    Share
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSaveTemplate}>
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>

                <hr />

                <Grid container spacing={2} mt={2} justifyContent="space-between" alignItems="center">
                    <TemplateContainer template={template} gearBySlot={gearBySlot} setGearBySlot={setGearBySlot} inventory={inventory} setInventory={setInventory} onStatisticsCalculated={handleStatisticsCalculated} statistics={statistics} />
                </Grid>

                <Drawer anchor="right" open={drawerOpen} onClose={handleToggleDrawer}>
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={handleDrawerClick}
                    >
                        <List>
                            {sharedUsers.map((user, index) => (
                                <ListItem key={user.uid} secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSharedUser(user.uid)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }>
                                    <ListItemText primary={`${user.displayName || user.uid}`} />
                                </ListItem>
                            ))}
                        </List>
                        <Box p={2}>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    label="Friend's Display Name"
                                    value={sharedUserDisplayName}
                                    onChange={(e) => setSharedUserDisplayName(e.target.value)}
                                />
                            </Box>
                            <Button variant="contained" onClick={handleAddSharedUser}>
                                Add User
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
                {/* Snackbar for save status */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    message={saveStatus}
                />

            </React.Fragment>
        ) : (
            <Typography>Loading...</Typography>
        )
    );
};

export default TemplateEdit;
