import React, { useState } from 'react';
import { Box, TextField, Button, Snackbar, Alert } from '@mui/material';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { db } from '../../../firebase/config';
import { collection, addDoc } from 'firebase/firestore';

const GaherisCharacterCreate = ({ refreshCharacters }) => {
    const { user } = useAuthContext();
    const [name, setName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCreateCharacter = async () => {
        if (!user || !name) return;
        try {
            // Fetch character_web_id from the API based on the character's name
            const searchResponse = await fetch(`https://api.camelotherald.com/character/search?cluster=Gaheris&name=${encodeURIComponent(name)}`);
            const searchData = await searchResponse.json();

            const matchedCharacter = searchData.results.find(character => {
                const [firstName] = character.name.split(' ');
                return firstName.toLowerCase() === name.toLowerCase();
            });

            if (matchedCharacter) {
                const characterId = matchedCharacter.character_web_id;

                // Store only the character name and characterId in Firebase
                const newCharacter = {
                    name,
                    characterId
                };

                // Adjusted collection reference with 'gaheris' as a document and 'characters' as a subcollection
                await addDoc(collection(db, `users/${user.uid}/gaheris`), newCharacter);
                refreshCharacters();
                setName(''); // Clear the input field after successful addition

                setSnackbarMessage('Character added successfully.');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage('Character not found.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.error('Error adding character:', error);
            setSnackbarMessage('Error adding character.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCreateCharacter();
        }
    };

    return (
        <div>
            <Box mb={2} display="flex" alignItems="center">
                <TextField
                    label="Character Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={handleKeyPress}
                    fullWidth
                    sx={{ marginRight: 2 }}
                />
                <Button variant="contained" color="primary" onClick={handleCreateCharacter}>
                    Add
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default GaherisCharacterCreate;
