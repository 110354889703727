import React, { useState } from 'react';
import { Grid, TextField, Paper, Button, Typography, Select, MenuItem, Switch, FormControlLabel } from '@mui/material';
import customItemData from '../../../data/customItemData.json';
import calculateSingleItemUtility from '../../../utility/calculateUtility';

const defaultItemState = {
    id: 0,
    name: '',
    category: '',
    realm: '',
    icon: 0,
    artifact: false,
    requirements: { level_required: 0 },
    bonuses: [],
    abilities: [],
    type_data: {
        dps: '',
        clamped_dps: '',
        speed: '',
        damage_type: '',
        base_quality: 100,
        two_handed: 0,
        left_handed: 0,
        skill_used: ''
    },
    use_duration: 0,
    bonus_level: 0,
    delve_text: '',
    utility: 0,
};

const skillOptions = {
    Albion: ['Bow', 'Crossbow', 'Crush', 'First Wraps', 'Flexible', 'Longbow', 'Mauler Staff', 'Painworking', 'Polearm', 'Slash', 'Staff', 'Thrust', 'Two Handed'],
    Midgard: ['Axe', 'Composite Bow', 'First Wraps', 'Hammer', 'Hand to Hand', 'Mauler Staff', 'Spear', 'Staff', 'Sword', 'Thrown'],
    Hibernia: ['Blades', 'Blunt', 'Bow', 'Celtic Spear', 'Fist Wraps', 'Large Weaponry', 'Mauler Staff', 'Piercing', 'Recurve Bow', 'Scythe', 'Staff']
};

const ItemAdd = () => {
    const [newItem, setNewItem] = useState({ ...defaultItemState });
    const [hasAbilities, setHasAbilities] = useState(false);

    const updateUtility = (updatedItem) => {
        const utility = calculateSingleItemUtility(updatedItem);
        setNewItem({ ...updatedItem, utility });
    };

    const handleInputChange = (field, nestedField = null) => (event) => {
        const value = event.target.value;
        if (nestedField) {
            const updatedItem = {
                ...newItem,
                [field]: {
                    ...newItem[field],
                    [nestedField]:
                        ['dps', 'clamped_dps', 'speed', 'level_required'].includes(nestedField)
                            ? parseFloat(value)
                            : ['two_handed', 'left_handed', 'base_quality'].includes(nestedField)
                                ? parseInt(value, 10)
                                : value
                }
            };
            setNewItem(updatedItem);
        } else {
            const updatedItem = {
                ...newItem,
                [field]:
                    ['id', 'icon', 'use_duration', 'bonus_level', 'utility'].includes(field)
                        ? parseInt(value, 10)
                        : value
            };
            setNewItem(updatedItem);
        }
    };

    const handleBonusChange = (index, field) => (event) => {
        const value = event.target.value;
        let updatedBonuses = [...newItem.bonuses];
        let updatedBonus = {
            ...updatedBonuses[index],
            [field]: field === 'value' ? parseInt(value, 10) : value
        };

        if (field === 'type') {
            const requiresId = getBonusIds(value, newItem.realm).length > 0;

            if (!requiresId) {
                delete updatedBonus.id;
            } else if (!updatedBonus.id) {
                updatedBonus.id = getBonusIds(value, newItem.realm)[0];
            }
        }

        updatedBonuses[index] = updatedBonus;
        const updatedItem = { ...newItem, bonuses: updatedBonuses };
        updateUtility(updatedItem); // Recalculate utility when bonuses change
    };

    const handleAddBonus = () => {
        const updatedItem = {
            ...newItem,
            bonuses: [...newItem.bonuses, { type: '', value: 0, id: '' }]
        };
        updateUtility(updatedItem); // Recalculate utility when a bonus is added
    };

    const handleRemoveBonus = (index) => {
        const updatedBonuses = newItem.bonuses.filter((_, i) => i !== index);
        const updatedItem = { ...newItem, bonuses: updatedBonuses };
        updateUtility(updatedItem); // Recalculate utility when a bonus is removed
    };

    const getBonusTypes = (realm) => {
        if (realm && customItemData[realm]) {
            return Object.keys(customItemData[realm]);
        }
        return [];
    };

    const getBonusIds = (type, realm) => {
        if (realm && type && customItemData[realm] && customItemData[realm][type]) {
            return customItemData[realm][type];
        }
        return [];
    };

    const handleAbilityChange = (index, field) => (event) => {
        const updatedAbilities = [...newItem.abilities];
        updatedAbilities[index] = { ...updatedAbilities[index], [field]: event.target.value };
        setNewItem({ ...newItem, abilities: updatedAbilities });
    };

    const handleAddAbility = () => {
        setNewItem({
            ...newItem,
            abilities: [...newItem.abilities, {
                spell: '',
                position: '/use1',
                power_level: 0,
                magic_type: 'Offensive Proc', // Default to 'Offensive Proc'
                spell_id: ''
            }]
        });
    };

    const handleRemoveAbility = (index) => {
        const updatedAbilities = newItem.abilities.filter((_, i) => i !== index);
        setNewItem({ ...newItem, abilities: updatedAbilities });
    };

    const handleReset = () => {
        setNewItem({ ...defaultItemState });
        setHasAbilities(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(newItem, null, 2));
        alert('JSON copied to clipboard!');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="h6">Add New Item</Typography>
                <TextField label="ID" type="number" value={newItem.id} onChange={handleInputChange('id')} fullWidth margin="normal" />
                <TextField label="Name" value={newItem.name} onChange={handleInputChange('name')} fullWidth margin="normal" />
                <Typography variant="subtitle1" style={{ marginTop: 16 }}>Category</Typography>
                <Select
                    label="Category"
                    value={newItem.category}
                    onChange={handleInputChange('category')}
                    fullWidth
                    displayEmpty
                    variant='outlined'>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Weapon">Weapon</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                    <MenuItem value="Jewelry">Jewelry</MenuItem>
                    <MenuItem value="Armor">Armor</MenuItem>
                </Select>
                <Typography variant="subtitle1" style={{ marginTop: 16 }}>Realm</Typography>
                <Select
                    label="Realm"
                    value={newItem.realm}
                    onChange={handleInputChange('realm')}
                    fullWidth
                    displayEmpty
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Albion">Albion</MenuItem>
                    <MenuItem value="Midgard">Midgard</MenuItem>
                    <MenuItem value="Hibernia">Hibernia</MenuItem>
                </Select>
                <TextField label="Icon" type="number" value={newItem.icon} onChange={handleInputChange('icon')} fullWidth margin="normal" />

                <Typography variant="subtitle1" style={{ marginTop: 16 }}>Artifact</Typography>
                <Select
                    label="Artifact"
                    value={newItem.artifact}
                    onChange={handleInputChange('artifact')}
                    fullWidth
                >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                </Select>

                <TextField
                    label="Level Required"
                    type="number"
                    value={newItem.requirements.level_required}
                    onChange={handleInputChange('requirements', 'level_required')}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Bonus Level"
                    type="number"
                    value={newItem.bonus_level}
                    onChange={handleInputChange('bonus_level')}
                    fullWidth
                    margin="normal"
                />

                <Typography variant="h6" style={{ marginTop: 16 }}>Bonuses</Typography>
                {newItem.bonuses.map((bonus, index) => (
                    <Grid container key={index} spacing={2} alignItems="center" mb={2}>
                        <Grid item>
                            <Select
                                value={bonus.type}
                                onChange={handleBonusChange(index, 'type')}
                                sx={{ minWidth: 120 }}
                                size="small"
                            >
                                {getBonusTypes(newItem.realm).map(type => (
                                    <MenuItem key={type} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {getBonusIds(bonus.type, newItem.realm).length > 0 && (
                            <Grid item>
                                <Select
                                    value={bonus.id || ''}
                                    onChange={handleBonusChange(index, 'id')}
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    {getBonusIds(bonus.type, newItem.realm).map(id => (
                                        <MenuItem key={id} value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        )}
                        <Grid item>
                            <TextField
                                label="Value"
                                type="number"
                                value={bonus.value}
                                onChange={handleBonusChange(index, 'value')}
                                sx={{ width: 100 }}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color="error" onClick={() => handleRemoveBonus(index)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}

                <Button variant="outlined" onClick={handleAddBonus}>Add Bonus</Button>

                {newItem.category === 'Weapon' && (
                    <>
                        <Typography variant="h6" style={{ marginTop: 16 }}>Weapon Data</Typography>
                        <TextField
                            label="DPS"
                            type="number"
                            value={newItem.type_data.dps}
                            onChange={handleInputChange('type_data', 'dps')}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Clamped DPS"
                            type="number"
                            value={newItem.type_data.clamped_dps}
                            onChange={handleInputChange('type_data', 'clamped_dps')}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Speed"
                            type="number"
                            value={newItem.type_data.speed}
                            onChange={handleInputChange('type_data', 'speed')}
                            fullWidth
                            margin="normal"
                        />
                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>Damage Type</Typography>
                        <Select
                            label="Damage Type"
                            value={newItem.type_data.damage_type}
                            onChange={handleInputChange('type_data', 'damage_type')}
                            fullWidth
                        >
                            <MenuItem value="Slash">Slash</MenuItem>
                            <MenuItem value="Crush">Crush</MenuItem>
                            <MenuItem value="Thrust">Thrust</MenuItem>
                            <MenuItem value="Elemental">Elemental</MenuItem>
                            <MenuItem value="Spirit">Spirit</MenuItem>
                            <MenuItem value="Siege">Siege</MenuItem>
                        </Select>
                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>Two Handed</Typography>
                        <Select
                            label="Two Handed"
                            value={newItem.type_data.two_handed}
                            onChange={handleInputChange('type_data', 'two_handed')}
                            fullWidth
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>Left Handed</Typography>
                        <Select
                            label="Left Handed"
                            value={newItem.type_data.left_handed}
                            onChange={handleInputChange('type_data', 'left_handed')}
                            fullWidth
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>Skill Used</Typography>
                        <Select
                            label="Skill Used"
                            value={newItem.type_data.skill_used}
                            onChange={handleInputChange('type_data', 'skill_used')}
                            fullWidth
                        >
                            {skillOptions[newItem.realm]?.map((skill) => (
                                <MenuItem key={skill} value={skill}>
                                    {skill}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                <Typography variant="h6" style={{ marginTop: 16 }}>Abilities</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasAbilities}
                            onChange={(event) => setHasAbilities(event.target.checked)}
                        />
                    }
                    label="Include Abilities"
                />
                {hasAbilities && newItem.abilities.map((ability, index) => (
                    <Grid container key={index} spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Select
                                value={ability.magic_type}
                                onChange={handleAbilityChange(index, 'magic_type')}
                                fullWidth
                            >
                                <MenuItem value="Offensive Proc">Offensive Proc</MenuItem>
                                <MenuItem value="Charge">Charge</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Spell"
                                value={ability.spell}
                                onChange={handleAbilityChange(index, 'spell')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={ability.position}
                                onChange={handleAbilityChange(index, 'position')}
                                fullWidth
                            >
                                <MenuItem value="/use1">/use1</MenuItem>
                                <MenuItem value="/use2">/use2</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Power Level"
                                type="number"
                                value={ability.power_level}
                                onChange={handleAbilityChange(index, 'power_level')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Spell ID"
                                value={ability.spell_id}
                                onChange={handleAbilityChange(index, 'spell_id')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="error" onClick={() => handleRemoveAbility(index)}>
                                Remove Ability
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                {hasAbilities && (
                    <Button variant="outlined" onClick={handleAddAbility} style={{ marginTop: '10px' }}>
                        Add Ability
                    </Button>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Button variant="contained" color="primary" onClick={handleCopy} style={{ marginTop: '10px' }}>
                    Copy
                </Button>
                <Button variant="contained" color="secondary" onClick={handleReset} style={{ marginTop: '10px', marginLeft: '10px' }}>
                    Reset
                </Button>
                <Paper style={{ padding: 16, height: '100%', overflow: 'auto' }}>
                    <pre>{JSON.stringify(newItem, null, 2)}</pre>
                </Paper>

            </Grid>
        </Grid>
    );
};

export default ItemAdd;
