import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, List, ListItem, Typography, Chip, Tooltip, IconButton } from '@mui/material';
import { useAuthContext } from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, query, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
}));

const getRealmStyles = (realm) => {
    switch (realm) {
        case 'Albion':
            return { color: 'red', borderColor: 'red' };
        case 'Hibernia':
            return { color: 'green', borderColor: 'green' };
        case 'Midgard':
            return { color: 'blue', borderColor: 'blue' };
        default:
            return {};
    }
};

const LookingForGroup = () => {
    const { user } = useAuthContext();
    const [characters, setCharacters] = useState([]);
    const [selectedCharacters, setSelectedCharacters] = useState([]);
    const [lfgModalOpen, setLfgModalOpen] = useState(false);
    const [lfgAds, setLfgAds] = useState([]);
    const [editingAd, setEditingAd] = useState(null);

    // Fetch user characters on Ywain server
    const fetchCharacters = async () => {
        if (!user) return;
        try {
            const q = query(collection(db, `users/${user.uid}/characters`));
            const querySnapshot = await getDocs(q);
            const fetchedCharacters = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(character => character.server === 'Ywain'); // Only show characters on Ywain
            setCharacters(fetchedCharacters);
        } catch (error) {
            console.error('Error fetching characters:', error);
        }
    };

    // Fetch active LFG ads
    const fetchLfgAds = async () => {
        try {
            const q = query(collection(db, 'lfg'));
            const querySnapshot = await getDocs(q);
            const fetchedLfgAds = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLfgAds(fetchedLfgAds);
        } catch (error) {
            console.error('Error fetching LFG ads:', error);
        }
    };

    useEffect(() => {
        fetchCharacters();
        fetchLfgAds(); // Fetch LFG ads on component mount
    }, [user]);

    const handleCharacterToggle = (characterId) => {
        setSelectedCharacters((prevSelected) =>
            prevSelected.includes(characterId)
                ? prevSelected.filter(id => id !== characterId)
                : [...prevSelected, characterId]
        );
    };

    const handleSetLfg = async () => {
        if (!user) return;

        try {
            const selectedCharacterDetails = characters.filter(character => selectedCharacters.includes(character.id));
            const lfgAd = {
                displayName: user.displayName,
                characters: selectedCharacterDetails.map(({ name, class: characterClass, realm }) => ({ name, class: characterClass, realm })),
                userId: user.uid,
            };

            if (editingAd) {
                // Update existing ad
                const adRef = doc(db, 'lfg', editingAd.id);
                await updateDoc(adRef, lfgAd);
                setEditingAd(null);
            } else {
                // Create new ad
                await addDoc(collection(db, 'lfg'), lfgAd);
            }

            setLfgModalOpen(false);
            setSelectedCharacters([]);
            fetchLfgAds(); // Refresh the list of LFG ads
        } catch (error) {
            console.error('Error setting LFG ad:', error);
        }
    };

    const handleOpenLfgModal = (ad = null) => {
        if (ad) {
            // Set existing ad for editing
            setEditingAd(ad);
            setSelectedCharacters(ad.characters.map(char => char.id));
        }
        setLfgModalOpen(true);
    };

    const handleCloseLfgModal = () => {
        setLfgModalOpen(false);
        setSelectedCharacters([]);
        setEditingAd(null);
    };

    const handleDeleteAd = async (adId) => {
        try {
            await deleteDoc(doc(db, 'lfg', adId));
            fetchLfgAds();
        } catch (error) {
            console.error('Error deleting LFG ad:', error);
        }
    };

    return (
        <Box>
            {/* Button to open the LFG creation modal */}
            <Button variant="contained" color="primary" onClick={() => handleOpenLfgModal()}>
                Create LFG Ad
            </Button>

            {/* LFG Creation/Editing Modal */}
            <Dialog open={lfgModalOpen} onClose={handleCloseLfgModal}>
                <DialogTitle>{editingAd ? 'Edit LFG Ad' : 'Select Characters for LFG'}</DialogTitle>
                <DialogContent>
                    <List>
                        {characters.map(character => (
                            <ListItem key={character.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedCharacters.includes(character.id)}
                                            onChange={() => handleCharacterToggle(character.id)}
                                        />
                                    }
                                    label={`${character.name} (${character.class}, ${character.realm})`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLfgModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSetLfg} color="primary">
                        {editingAd ? 'Update LFG' : 'Set LFG'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Displaying List of Active LFG Ads */}
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Players Looking for Group
                </Typography>
                <List>
                    {lfgAds.map(ad => (
                        <StyledListItem key={ad.id} alignItems="flex-start">
                            <Box display="flex" alignItems="center" width="100%">
                                <Typography variant="subtitle1">{ad.displayName}</Typography>
                                {user && user.uid === ad.userId && (
                                    <Box ml="auto">
                                        <IconButton onClick={() => handleOpenLfgModal(ad)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteAd(ad.id)} color="secondary">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                            <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                                {ad.characters.map((character, index) => {
                                    const realmStyles = getRealmStyles(character.realm);
                                    return (
                                        <Tooltip key={index} title={character.name} arrow>
                                            <Chip
                                                label={character.class}
                                                variant="outlined"
                                                style={{
                                                    borderColor: realmStyles.borderColor,
                                                    color: realmStyles.color,
                                                    fontSize: '0.8rem', // Smaller font size for compact display
                                                }}
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </Box>
                        </StyledListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default LookingForGroup;
