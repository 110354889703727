import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Grid, TableSortLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ItemList = ({ visibleItems, page, rowsPerPage, handlePageChange, handleRowsPerPageChange, displayedItems, setLastHoveredItem, filters = [] }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    // State for sorting
    const [sortConfig, setSortConfig] = useState({ key: 'utility', direction: 'desc' });

    // Apply dynamic filters to items passed from visibleItems
    const applyFilters = (item) => {
        return (filters || []).every(filter => {
            if (!Array.isArray(item.bonuses)) return false;
            const itemBonus = item.bonuses.find(bonus => bonus.type === filter.type && (!filter.id || bonus.id === filter.id));
            return itemBonus && parseInt(itemBonus.value, 10) >= parseInt(filter.value, 10);
        });
    };

    const filteredItems = visibleItems.filter(applyFilters);

    // Sort items based on the selected sorting configuration
    const sortedItems = [...filteredItems].sort((a, b) => {
        const { key, direction } = sortConfig;
        if (key === 'name') {
            return direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (key === 'utility') {
            return direction === 'asc' ? a.utility - b.utility : b.utility - a.utility;
        }
        return 0;
    });

    // Toggle sorting configuration when a header is clicked
    const handleSortChange = (column) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === column) {
                // Toggle direction if the same column is clicked
                return { ...prevConfig, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
            }
            // Set new column and default to descending order
            return { key: column, direction: 'desc' };
        });
    };

    return (
        <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: primaryColor }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'name'}
                                    direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSortChange('name')}
                                    style={{ color: primaryColor }}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ color: primaryColor }}>Category</TableCell>
                            <TableCell style={{ color: primaryColor }}>Slot</TableCell>
                            <TableCell style={{ color: primaryColor }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'utility'}
                                    direction={sortConfig.key === 'utility' ? sortConfig.direction : 'desc'}
                                    onClick={() => handleSortChange('utility')}
                                    style={{ color: primaryColor }}
                                >
                                    Utility
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedItems.length > 0 ? (
                            sortedItems.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item) => (
                                <TableRow
                                    key={item.id}
                                    onMouseEnter={() => setLastHoveredItem(item)}
                                >
                                    <TableCell style={{ color: primaryColor }}>{item.name}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{item.category}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{item.slot}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{item.utility}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center" style={{ color: primaryColor }}>No items found.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={displayedItems.length}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[25, 50, 100]}
            />
        </Grid>
    );
};

export default ItemList;
